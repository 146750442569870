/* eslint-disable react/prop-types */
import { createBrowserRouter } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { lazy } from "react";
import { Button, Result } from "antd";
import { selectUser } from "../store/userSlice";
import { useSelector } from "react-redux";
import ClassAttendanceManagement from "../screen/ClassAttendanceMangement";
const StudentLeave = lazy(()=>import("../components/StudentLeave"));
const StudentTeachers = lazy(()=>import("../components/StudentTeachers"));
const Student = lazy(() => import("../screen/student"));
const OwnDrawer = lazy(() => import("../hooks/Owndrawer"));
const Dashboard = lazy(() => import("../screen/dashboard"));
const Leave = lazy(() => import("../screen/Leave"));
const Courses = lazy(() => import("../screen/Courses"));
const Medium = lazy(() => import("../screen/Medium"));
const Login = lazy(() => import("../screen/Login"));
const PendingFee = lazy(() => import("../screen/PendingFee"));
const Reports = lazy(() => import("../screen/Reports"));
const LibraryManagement = lazy(() => import("../screen/LibraryManagement"));
const SyllabusManagement = lazy(() => import("../screen/SyllabusManagement"));
const DriverManagement = lazy(() => import("../screen/DriverManagement"));
const EventManagement = lazy(() => import("../screen/EventManagement"));
const Profile = lazy(() => import("../screen/Profile"));
const Gallery = lazy(() => import("../screen/Gallery"));
const BookManagement = lazy(() => import("../screen/BookManagement"));
const Expense = lazy(() => import("../screen/Expense"));
const TimetableManagement = lazy(() => import("../screen/TimetableManagement"));
const SportsManagement = lazy(() => import("../screen/SportsManagement"))
const SalaryManagement = lazy(() => import("../screen/SalaryManagement"));
const Attendance = lazy(() => import("../screen/Attendance"));
const Performance = lazy(() => import("../screen/Performance"));
const Access = lazy(() => import("../screen/Access"));
const AddEmployee = lazy(() => import("../screen/AddEmployee"));
const ClassManagement = lazy(() => import("../screen/ClassManagement"));
const Timetable = lazy(() => import("../screen/Student/Timetable"));
const StudentProfile = lazy(() => import("../components/StudentProfile"));
const StudentDashboard = lazy(() =>
  import("../screen/Student/StudentDashboard")
);
const StudentResult = lazy(() => import("../components/StudentResult"));
const StudentFeesTable = lazy(() => import("../components/StudentFeesTable"));
const StudentSports = lazy(() => import("../screen/Student/StudentSports"));
const StudentEvents = lazy(() => import("../screen/Student/StudentEvents"));
const StudentWorksheet = lazy(() =>
  import("../screen/Student/StudentWorksheet")
);
const ExamSchedule = lazy(() => import("../components/ExamSchedule"));
const TeacherTimetable = lazy(() =>
  import("../screen/Employee/TeacherTimetable")
);
const EmployeeDashboard = lazy(() =>
  import("../screen/Employee/EmployeeDashboard")
);
const WorksheetManagement = lazy(() => import("../screen/WorksheetManagement"));
const NoticeBoard = lazy(() => import("../components/NoticeBoard"));
const ForumCard = lazy(() => import("../components/ForumCard"));
const RoomAllocation = lazy(() => import("../components/RoomAllocation"));
const HostelFeeManagement = lazy(() =>
  import("../components/HostelFeeManagement")
);
const BusRouteManagement = lazy(() =>
  import("../components/BusRouteManagement")
);
const TeacherAttendance = lazy(() =>
  import("../components/TeacherAttendanceManagement")
);
const FeeCollection = lazy(() => import("../screen/Fee Collection"));
const PendingFees = lazy(() => import("../screen/PendingFee"));

const NotFound = () => (
  <Result
    status="404"
    title="404 Not Found"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Button type="primary" href="/">
        Back Home
      </Button>
    }
  />
);

export default function AppRoutes() {
  const user = useSelector(selectUser);

  const RoleBasedRoutes = ({ role }) => {
    if (
      role === "Class Teacher" ||
      role === "Head Teacher" ||
      role === "Subject Teacher"
    ) {
      return (
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <OwnDrawer>
                <EmployeeDashboard user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/login"
            element={
              <Login />
            }
          />
          <Route
            path="/students"
            element={
              <OwnDrawer>
                <Student user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/attendance"
            element={
              <OwnDrawer>
                <Attendance user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/performance"
            element={
              <OwnDrawer>
                <StudentResult user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/library"
            element={
              <OwnDrawer>
                <BookManagement user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/employees"
            element={
              <OwnDrawer>
                <AddEmployee user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/attendance"
            element={
              <OwnDrawer title="Employee Attendance">
                <TeacherAttendance user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/salary"
            element={
              <OwnDrawer>
                <SalaryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/leaves"
            element={
              <OwnDrawer title="Leave">
                <Leave user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/courses"
            element={
              <OwnDrawer>
                <Courses />
              </OwnDrawer>
            }
          />
          <Route
            path="/syllabus"
            element={
              <OwnDrawer>
                <SyllabusManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/exam-schedule"
            element={
              <OwnDrawer>
                <ExamSchedule user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/timetable"
            element={
              <OwnDrawer>
                <TimetableManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/fee-structure"
            element={
              <OwnDrawer title="fee structure">
                <></>
              </OwnDrawer>
            }
          />
          <Route
            path="/fee-collection"
            element={
              <OwnDrawer title="fee Collection">
                <FeeCollection user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/pending-fee"
            element={
              <OwnDrawer title="pending fee">
                <PendingFees user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/finance/expenses"
            element={
              <OwnDrawer>
                <Expense user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/hostel/room-allocation"
            element={
              <OwnDrawer title="room-allocation">
                <RoomAllocation user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/hostel/fees"
            element={
              <OwnDrawer>
                <HostelFeeManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/transport/routes"
            element={
              <OwnDrawer>
                <BusRouteManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/transport/drivers"
            element={
              <OwnDrawer title="Drivers">
                <DriverManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/library/books"
            element={
              <OwnDrawer>
                <BookManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/library/return-books"
            element={
              <OwnDrawer>
                <LibraryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/sports"
            element={
              <OwnDrawer>
                <SportsManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/events"
            element={
              <OwnDrawer>
                <EventManagement user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/employee/timetable"
            element={
              <OwnDrawer>
                <TeacherTimetable />
              </OwnDrawer>
            }
          />
          <Route
            path="/employee/result"
            element={
              <OwnDrawer>
                <></>
              </OwnDrawer>
            }
          />
          <Route
            path="/reports"
            element={
              <OwnDrawer>
                <Reports user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/gallery"
            element={
              <OwnDrawer title="Gallery">
                <Gallery user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/settings/profile"
            element={
              <OwnDrawer title="profile">
                <Profile user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/access"
            element={
              <OwnDrawer title="access">
                <Access user={user} />
              </OwnDrawer>
            }
          />

        </Routes>

      );
    }
    else if (
      role === "Principal" ||
      role === "Vice Principal"
    ) {
      return (
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Login />} />
          <Route
            path="/login"
            element={
              <Login />
            }
          />
          <Route
            path="/dashboard"
            element={
              <OwnDrawer>
                <Dashboard user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students"
            element={
              <OwnDrawer title="student">
                <Student user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/attendance"
            element={
              <OwnDrawer>
                <ClassAttendanceManagement user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/students/performance"
            element={
              <OwnDrawer title="Leave">
                <Performance user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/library"
            element={
              <OwnDrawer title="Library">
                <LibraryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees"
            element={
              <OwnDrawer>
                <AddEmployee user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/attendance"
            element={
              <OwnDrawer title="Employee Attendance">
                <TeacherAttendance user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/salary"
            element={
              <OwnDrawer>
                <SalaryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/leaves"
            element={
              <OwnDrawer title="Leave">
                <Leave user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/courses"
            element={
              <OwnDrawer title="Courses">
                <Courses user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/syllabus"
            element={
              <OwnDrawer title="Syllabus">
                <SyllabusManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/timetable"
            element={
              <OwnDrawer>
                <TimetableManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/exam-schedule"
            element={
              <OwnDrawer title="Exam Schedule Fee">
                <ExamSchedule user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/fee-structure"
            element={
              <OwnDrawer title="fee structure">
                <></>
              </OwnDrawer>
            }
          />
          <Route
            path="/fee-collection"
            element={
              <OwnDrawer title="fee Collection">
                <FeeCollection user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/pending-fee"
            element={
              <OwnDrawer title="pending fee">
                <PendingFees user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/finance/expenses"
            element={
              <OwnDrawer>
                <Expense user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/hostel/room-allocation"
            element={
              <OwnDrawer title="room-allocation">
                <RoomAllocation user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/hostel/fees"
            element={
              <OwnDrawer>
                <HostelFeeManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/transport/routes"
            element={
              <OwnDrawer>
                <BusRouteManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/transport/drivers"
            element={
              <OwnDrawer title="Drivers">
                <DriverManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/library/books"
            element={
              <OwnDrawer>
                <BookManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/library/return-books"
            element={
              <OwnDrawer title="Library">
                <LibraryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/sports"
            element={
              <OwnDrawer>
                <SportsManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/events"
            element={
              <OwnDrawer title="Events">
                <EventManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/reports"
            element={
              <OwnDrawer title="Reports">
                <Reports user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/gallery"
            element={
              <OwnDrawer title="Gallery">
                <Gallery user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/settings/profile"
            element={
              <OwnDrawer title="Dashboard">
                <Profile user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/access"
            element={
              <OwnDrawer title="Leave">
                <Access user={user} />
              </OwnDrawer>
            }
          />
        </Routes>
      );
    }
    else if (
      role === "Admin" ||
      role === "Administrator") {
      return (
        <Routes>
          <Route
            path="/login"
            element={
              <Login />
            }
          />
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <OwnDrawer>
                <Dashboard user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/classTeacher"
            element={
              <OwnDrawer>
                <ClassManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/salary"
            element={
              <OwnDrawer>
                <SalaryManagement user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/settings/profile"
            element={
              <OwnDrawer title="Dashboard">
                <Profile user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/gallery"
            element={
              <OwnDrawer title="Gallery">
                <Gallery user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/students"
            element={
              <OwnDrawer title="Student">
                <Student user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/leaves"
            element={
              <OwnDrawer title="Leave">
                <Leave user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/access"
            element={
              <OwnDrawer title="Leave">
                <Access user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/performance"
            element={
              <OwnDrawer title="Leave">
                <StudentResult user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/reports"
            element={
              <OwnDrawer title="Reports">
                <Reports user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/library"
            element={
              <OwnDrawer title="Library">
                <LibraryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/library/return-books"
            element={
              <OwnDrawer title="Library">
                <LibraryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/syllabus"
            element={
              <OwnDrawer title="Syllabus">
                <SyllabusManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/transport/drivers"
            element={
              <OwnDrawer title="Drivers">
                <DriverManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/events"
            element={
              <OwnDrawer title="Events">
                <EventManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/courses"
            element={
              <OwnDrawer title="Courses">
                <Courses user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/medium"
            element={
              <OwnDrawer title="Medium">
                <Medium user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/pendingfee"
            element={
              <OwnDrawer title="Pending Fee">
                <PendingFee user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/exam-schedule"
            element={
              <OwnDrawer title="Exam Schedule Fee">
                <ExamSchedule user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/homework"
            element={
              <OwnDrawer title="Homework">
                <Leave user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/assignment"
            element={
              <OwnDrawer title="Assignment">
                <Leave user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/academic"
            element={
              <OwnDrawer title="Academic">
                <Leave user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employee"
            element={
              <OwnDrawer>
                <AddEmployee user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees"
            element={
              <OwnDrawer title="Employee">
                <AddEmployee user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/attendance"
            element={
              <OwnDrawer title="Employee Attendance">
                <TeacherAttendance user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/fee-structure"
            element={
              <OwnDrawer title="fee structure">
                <></>
              </OwnDrawer>
            }
          />
          <Route
            path="/fee-collection"
            element={
              <OwnDrawer title="fee Collection">
                <FeeCollection user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/pending-fee"
            element={
              <OwnDrawer title="pending fee">
                <PendingFees user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/worksheet"
            element={
              <OwnDrawer title="Add worksheets">
                <WorksheetManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/noticeboard"
            element={
              <OwnDrawer title="Add notice">
                <NoticeBoard user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/forum"
            element={
              <OwnDrawer title="forum discussion">
                <ForumCard user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/hostel/room-allocation"
            element={
              <OwnDrawer title="room-allocation">
                <RoomAllocation user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/hostel/fees"
            element={
              <OwnDrawer>
                <HostelFeeManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/transport/routes"
            element={
              <OwnDrawer>
                <BusRouteManagement user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/library/books"
            element={
              <OwnDrawer>
                <BookManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/finance/expenses"
            element={
              <OwnDrawer>
                <Expense user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/timetable"
            element={
              <OwnDrawer>
                <TimetableManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/sports"
            element={
              <OwnDrawer>
                <SportsManagement user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/student/timetable"
            element={
              <OwnDrawer>
                <AddEmployee user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/attendance"
            element={
              <OwnDrawer>
                <ClassAttendanceManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      );
    }
    else if (
      role === "Librarian" ||
      role === "Lab Assistant" ||
      role === "Physical Trainer") {
      return (
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Login />} />
          <Route
            path="/login"
            element={
              <Login />
            }
          />
          <Route
            path="/dashboard"
            element={
              <OwnDrawer>
                <EmployeeDashboard user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students"
            element={
              <OwnDrawer title="student">
                <Student user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/attendance"
            element={
              <OwnDrawer>
                <ClassAttendanceManagement user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/students/performance"
            element={
              <OwnDrawer title="Leave">
                <Performance user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/library"
            element={
              <OwnDrawer title="Library">
                <LibraryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees"
            element={
              <OwnDrawer>
                <AddEmployee user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/attendance"
            element={
              <OwnDrawer title="Employee Attendance">
                <TeacherAttendance user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/salary"
            element={
              <OwnDrawer>
                <SalaryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/leaves"
            element={
              <OwnDrawer title="Leave">
                <Leave user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/courses"
            element={
              <OwnDrawer title="Courses">
                <Courses user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/syllabus"
            element={
              <OwnDrawer title="Syllabus">
                <SyllabusManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/timetable"
            element={
              <OwnDrawer>
                <TimetableManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/exam-schedule"
            element={
              <OwnDrawer title="Exam Schedule Fee">
                <ExamSchedule user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/fee-structure"
            element={
              <OwnDrawer title="fee structure">
                <></>
              </OwnDrawer>
            }
          />
          <Route
            path="/fee-collection"
            element={
              <OwnDrawer title="fee Collection">
                <FeeCollection user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/pending-fee"
            element={
              <OwnDrawer title="pending fee">
                <PendingFees user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/finance/expenses"
            element={
              <OwnDrawer>
                <Expense user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/hostel/room-allocation"
            element={
              <OwnDrawer title="room-allocation">
                <RoomAllocation user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/hostel/fees"
            element={
              <OwnDrawer>
                <HostelFeeManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/transport/routes"
            element={
              <OwnDrawer>
                <BusRouteManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/transport/drivers"
            element={
              <OwnDrawer title="Drivers">
                <DriverManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/library/books"
            element={
              <OwnDrawer>
                <BookManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/library/return-books"
            element={
              <OwnDrawer title="Library">
                <LibraryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/sports"
            element={
              <OwnDrawer>
                <SportsManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/events"
            element={
              <OwnDrawer title="Events">
                <EventManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/reports"
            element={
              <OwnDrawer title="Reports">
                <Reports user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/gallery"
            element={
              <OwnDrawer title="Gallery">
                <Gallery user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/settings/profile"
            element={
              <OwnDrawer title="Dashboard">
                <Profile user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/access"
            element={
              <OwnDrawer title="Leave">
                <Access user={user} />
              </OwnDrawer>
            }
          />
        </Routes>
      );
    }
    else if (
      role === "Peon") {
      return (
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Login />} />
          <Route
            path="/login"
            element={
              <Login />
            }
          />
          <Route
            path="/dashboard"
            element={
              <OwnDrawer>
                <EmployeeDashboard user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students"
            element={
              <OwnDrawer title="student">
                <Student user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/attendance"
            element={
              <OwnDrawer>
                <ClassAttendanceManagement user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/students/performance"
            element={
              <OwnDrawer title="Leave">
                <Performance user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/library"
            element={
              <OwnDrawer title="Library">
                <LibraryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees"
            element={
              <OwnDrawer>
                <AddEmployee user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/attendance"
            element={
              <OwnDrawer title="Employee Attendance">
                <TeacherAttendance user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/salary"
            element={
              <OwnDrawer>
                <SalaryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/leaves"
            element={
              <OwnDrawer title="Leave">
                <Leave user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/courses"
            element={
              <OwnDrawer title="Courses">
                <Courses user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/syllabus"
            element={
              <OwnDrawer title="Syllabus">
                <SyllabusManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/timetable"
            element={
              <OwnDrawer>
                <TimetableManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/exam-schedule"
            element={
              <OwnDrawer title="Exam Schedule Fee">
                <ExamSchedule user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/fee-structure"
            element={
              <OwnDrawer title="fee structure">
                <></>
              </OwnDrawer>
            }
          />
          <Route
            path="/fee-collection"
            element={
              <OwnDrawer title="fee Collection">
                <FeeCollection user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/pending-fee"
            element={
              <OwnDrawer title="pending fee">
                <PendingFees user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/finance/expenses"
            element={
              <OwnDrawer>
                <Expense user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/hostel/room-allocation"
            element={
              <OwnDrawer title="room-allocation">
                <RoomAllocation user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/hostel/fees"
            element={
              <OwnDrawer>
                <HostelFeeManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/transport/routes"
            element={
              <OwnDrawer>
                <BusRouteManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/transport/drivers"
            element={
              <OwnDrawer title="Drivers">
                <DriverManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/library/books"
            element={
              <OwnDrawer>
                <BookManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/library/return-books"
            element={
              <OwnDrawer title="Library">
                <LibraryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/sports"
            element={
              <OwnDrawer>
                <SportsManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/events"
            element={
              <OwnDrawer title="Events">
                <EventManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/reports"
            element={
              <OwnDrawer title="Reports">
                <Reports user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/gallery"
            element={
              <OwnDrawer title="Gallery">
                <Gallery user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/settings/profile"
            element={
              <OwnDrawer title="Dashboard">
                <Profile user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/access"
            element={
              <OwnDrawer title="Leave">
                <Access user={user} />
              </OwnDrawer>
            }
          />
        </Routes>
      );
    }
    else if (
      role === "Accountant" ||
      role === "Clerk") {
      return (
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Login />} />
          <Route
            path="/login"
            element={
              <Login />
            }
          />
          <Route
            path="/dashboard"
            element={
              <OwnDrawer>
                <EmployeeDashboard user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employee/dashboard"
            element={
              <OwnDrawer>
                <EmployeeDashboard user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employee/fee"
            element={
              <OwnDrawer>
                <SalaryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employee/result"
            element={
              <OwnDrawer>
                <StudentResult user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employee/sports"
            element={
              <OwnDrawer>
                <SportsManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employee/events"
            element={
              <OwnDrawer>
                <EventManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employee/Profile"
            element={
              <OwnDrawer>
                <Profile user={user} />
              </OwnDrawer>
            }
          />
        </Routes>
      )
    }
    else if (
      role === "Counselor") {
      return (
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route
            path="/login"
            element={
              <Login />
            }
          />
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <OwnDrawer>
                <EmployeeDashboard user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students"
            element={
              <OwnDrawer title="student">
                <Student user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/attendance"
            element={
              <OwnDrawer>
                <ClassAttendanceManagement user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/students/performance"
            element={
              <OwnDrawer title="Leave">
                <Performance user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/students/library"
            element={
              <OwnDrawer title="Library">
                <LibraryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees"
            element={
              <OwnDrawer>
                <AddEmployee user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/attendance"
            element={
              <OwnDrawer title="Employee Attendance">
                <TeacherAttendance user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/salary"
            element={
              <OwnDrawer>
                <SalaryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/employees/leaves"
            element={
              <OwnDrawer title="Leave">
                <Leave user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/courses"
            element={
              <OwnDrawer title="Courses">
                <Courses user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/syllabus"
            element={
              <OwnDrawer title="Syllabus">
                <SyllabusManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/timetable"
            element={
              <OwnDrawer>
                <TimetableManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/exam-schedule"
            element={
              <OwnDrawer title="Exam Schedule Fee">
                <ExamSchedule user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/fee-structure"
            element={
              <OwnDrawer title="fee structure">
                <></>
              </OwnDrawer>
            }
          />
          <Route
            path="/fee-collection"
            element={
              <OwnDrawer title="fee Collection">
                <FeeCollection user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/pending-fee"
            element={
              <OwnDrawer title="pending fee">
                <PendingFees user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/finance/expenses"
            element={
              <OwnDrawer>
                <Expense user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/hostel/room-allocation"
            element={
              <OwnDrawer title="room-allocation">
                <RoomAllocation user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/hostel/fees"
            element={
              <OwnDrawer>
                <HostelFeeManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/transport/routes"
            element={
              <OwnDrawer>
                <BusRouteManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/transport/drivers"
            element={
              <OwnDrawer title="Drivers">
                <DriverManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/library/books"
            element={
              <OwnDrawer>
                <BookManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/library/return-books"
            element={
              <OwnDrawer title="Library">
                <LibraryManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/sports"
            element={
              <OwnDrawer>
                <SportsManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/events"
            element={
              <OwnDrawer title="Events">
                <EventManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/reports"
            element={
              <OwnDrawer title="Reports">
                <Reports user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/gallery"
            element={
              <OwnDrawer title="Gallery">
                <Gallery user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/settings/profile"
            element={
              <OwnDrawer title="Dashboard">
                <Profile user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/access"
            element={
              <OwnDrawer title="Leave">
                <Access user={user} />
              </OwnDrawer>
            }
          />
        </Routes>
      );
    }
    else if (
      role === "Student") {
      return (
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Login />} />
          <Route
            path="/login"
            element={
              <Login />
            }
          />

          <Route
            path="/dashboard"
            element={
              <OwnDrawer>
                <StudentDashboard user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/student/dashboard"
            element={
              <OwnDrawer>
                <StudentDashboard user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/student/timetable"
            element={
              <OwnDrawer>
                <Timetable user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/student/teachers"
            element={
              <OwnDrawer>
                <StudentTeachers user={user} />
              </OwnDrawer>
            }
          />
           <Route
            path="/student/leaves"
            element={
              <OwnDrawer>
                <StudentLeave user={user} />
              </OwnDrawer>
            }
          />

          <Route
            path="/student/result"
            element={
              <OwnDrawer>
                <StudentResult user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/student/fee"
            element={
              <OwnDrawer>
                <StudentFeesTable user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/student/sports"
            element={
              <OwnDrawer>
                <StudentSports user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/student/events"
            element={
              <OwnDrawer>
                <StudentEvents user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/student/hostel"
            element={
              <OwnDrawer>
                <HostelFeeManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/student/transport"
            element={
              <OwnDrawer>
                <BusRouteManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/student/syllabus"
            element={
              <OwnDrawer>
                <SyllabusManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/student/examSchedule"
            element={
              <OwnDrawer>
                <ExamSchedule user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/student/attendance"
            element={
              <OwnDrawer>
                <Attendance user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/student/profile"
            element={
              <OwnDrawer>
                <StudentProfile user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/student/library"
            element={
              <OwnDrawer>
                <BookManagement user={user} />
              </OwnDrawer>
            }
          />
          <Route
            path="/student/worksheet"
            element={
              <OwnDrawer>
                <StudentWorksheet user={user} />
              </OwnDrawer>
            }
          />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      );
    }
  };

  return (
    <BrowserRouter>
      <RoleBasedRoutes role={user.role} email={user.email} />
    </BrowserRouter>
  );
}
